export const info = [
 
  {
    jobid: "1",
    header: "React Native Developer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2025-01-06T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    linkId: "react-native-developer-at-charles-technologies-4116296219",
    exp: "2 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, or a related field.",
    skil: "React Native,React,Node.js ",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced React Native Developer to join our team and contribute to the development of cutting-edge mobile applications.",
    requriedexp: [
      "Minimum of 2 years of experience in mobile application development using React Native.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and mobile application development best practices.",
    ],
    skills: [
      "React Native: Advanced expertise in React Native for developing cross-platform mobile applications, including experience with native modules and performance optimization.",
      "JavaScript​: Expert-level proficiency in JavaScript, including ES6+ features.",
      "React: Proficient in React for front-end development, including hooks, context API, and state management libraries like Redux.",
      "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js and asynchronous programming.",
      "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, and querying.",
      "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, and Azure Storage.",
      "Git: Proficient in version control systems like Git, including branching, merging strategies, and pull request workflows.",
      "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, and automated testing.",
      "API Integration: Experience in integrating RESTful APIs and third-party services, including authentication and authorization mechanisms.",
      "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive and user-friendly interfaces.",
    ],

    responsePoints: [
      "Application Development: Develop and maintain high-quality mobile applications using React Native and React, ensuring code quality and performance.",
      "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability and security.",
      "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, and performance tuning.",
      "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
      "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, and monitoring.",
      "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
      "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling and debugging.",
      "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives.",
      "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing.",
      "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location: Conveniently located in the heart of the city, with parking facilities and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },


  {
    jobid: "2",
    header: "Azure Devops Engineer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2025-01-06T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "2 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, Business Administration, or a related field.",
    skil: "Roadmap Development,Backlog Prioritization",
    linkId: "azure-aevops-engineer-at-charles-technologies-4116269897",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a highly skilled and experienced Azure DevOps Engineer to join our team and ensure the cloud security and integrity of our cutting-edge projects.",
    requriedexp: [
      "Minimum of 2 years of experience in Azure DevOps with a focus on cloud security",
    ],
    foundationalKnowledge: [
      "Basic understanding of cybersecurity principles, best practices, and common threats.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, Cybersecurity, or a related field.",
    ],
    skills: [
      "Azure Fundamentals: Knowledge of Azure services and core security concepts.",
      "Azure Security Center: Experience with Azure Security Center for threat detection and assessment.",
      "Azure Active Directory: Understanding of Azure Active Directory user management and basic authentication concepts.",
      "Stakeholder Management: Strong skills in managing and communicating with stakeholders at all levels to gather requirements, provide updates, and align on priorities.",
      "Azure DevOps: Hands-on experience with Azure DevOps for CI/CD pipelines.",
    ],

    responsePointsCI: [
      "Design, implement, and maintain CI/CD pipelines using Azure DevOps",
      "Automate build, test, and deployment processes for applications",
      "Troubleshoot and resolve issues within CI/CD pipelines",
      "Implement and maintain automated testing frameworks",
    ],
    responsePointsSecurity: [
      "Assist with security assessments and vulnerability scans",
      "Support security controls implementation and maintenance",
      "Assist with incident response and investigation",
      "Contribute to security documentation and awareness programs",
    ],
    responsePointsCollaboration: [
      "Work closely with developers, and product owners to ensure smooth integration of security practices within the development process",
      "Participate in code reviews to identify potential security vulnerabilities",
      "Manage code repositories using Git, including branching strategies, pull request workflows, and merge conflict resolution",
    ],

    responsePointsLearning: [
      "Stay updated on the latest advancements in Azure DevOps and best security practices",
      "Continuously improve CI/CD pipelines for efficiency, security, and deployment reliability",
    ],


    Qualification: [],
    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  {
    jobid: "3",
    header: "Full Stack Developer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2025-01-06T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications (e.g., ISTQB, CSTE) are a plus.",
    skil: "Scripting Languages,Automation Testing",
    linkId:
      "full-stack-developer-at-charles-technologies-4116274420",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced MERN Stack Developer to join our team and lead the development of innovative web and mobile applications..",
    requriedexp: [
      "Minimum of 3 years of total experience in full stack application development. Extensive experience working with startups, small teams, and in fast-paced environments is highly desirable..",
    ],
    qualification: [
      "Bachelor's degree in Computer Science, Information Technology, or a related field. A Master’s degree is a plus. Relevant certifications are also a plus..",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and web/mobile application development best practices..",
    ],
    skills: [
      "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features, asynchronous programming, and modern frameworks.",
      "React Native: Extensive experience in developing cross-platform mobile applications using React Native, including performance optimization and native module integration.",
      "React: Advanced expertise in React for front-end development, including hooks, context API, state management libraries like Redux, and component lifecycle management.",
      "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js, RESTful API design, and asynchronous programming patterns.",
      "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, querying, and performance tuning.",
      "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, Azure Storage, and monitoring tools.",
      "Git: Proficient in version control systems like Git, including branching, merging strategies, pull request workflows, and conflict resolution.",
      "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, automated testing, and release management.",
      "API Integration: Experience in integrating RESTful APIs and third-party services, including OAuth, JWT, and other authentication and authorization mechanisms.",
      "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive, accessible, and user-friendly interfaces.",  
    ],
    responsePoints: [
      "Full Stack Development: Develop and maintain high-quality web and mobile applications using React Native, React, and Node.js, ensuring code quality, performance, and scalability.",
      "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability, security, and maintainability.",
      "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, partitioning, and performance tuning.",
      "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
      "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, monitoring, and rollback strategies.",
      "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
      "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling, debugging, and performance tuning.",
      "Collaboration: Work closely with developers, product owners, and other stakeholders to understand requirements and ensure quality throughout the development lifecycle.",
      "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing.",
      "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location: Conveniently located in the heart of the city, with parking facilities and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  {
    jobid: "4",
    header: "Software Engineer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2025-01-06T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor of Engineering in Computer Science, Data Science, AI or Machine Learning. A Master’s degree or relevant certifications are a plus.",
    skil: "Programming Languages,Cloud Services",
    linkId: "software-engineer-at-charles-technologies-4116278009",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced Software Engineer to join our team and contribute to the development of cutting-edge web and mobile applications.",
    requriedexp: [
      "Minimum of 3 years of experience in full stack application development.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and web/mobile application development best practices.",
    ],
    skills: [
      "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features",
      "React Native: Experience in developing cross-platform mobile applications using React Native",
      "React: Advanced expertise in React for front-end development, including hooks, context API, and state management libraries like Redux ",
      "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js and asynchronous programming",
      "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, and querying",
      "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, and Azure Storage",
      "Git: Proficient in version control systems like Git, including branching, merging strategies, and pull request workflows",
      "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, and automated testing",
      "API Integration: Experience in integrating RESTful APIs and third-party services, including authentication and authorization mechanisms",
      "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive and user-friendly interfaces",
    ],
    responsePoints: [
      "Full Stack Development: Develop and maintain high-quality web and mobile applications using React Native, React, and Node.js, ensuring code quality and performance",
      "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability and security",
      "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, and performance tuning",
      "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
      "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, and monitoring",
      "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices",
      "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling and debugging",
      "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives",
      "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team",
    ],

    Perks: [
      "Central Location: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },


  {
    jobid: "5",
    header: "Human Resources Officer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2025-01-06T00:00:00",
    Discipline: "Technology",
    reporting: "DGM - HR",
    exp: "5 Years",
    qual: "Master's degree in business administration or a related field (MBA-HR / MSW-HR)",
    skil: "Programming Languages,Cloud Services",
    linkId: "human-resources-officer-at-charles-technologies-4116271840",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced Human Resource-Talent Acquisition cum Learning & Development to join our team and lead the learning & Development and Talent Acquisition.",
    requriedexp: [
      "Minimum of 5 years of total experience in Human Resource- Talent Acquisition cum Learning & Development.",
    ],
    qualification: [
      "Master's degree in business administration or a related field (MBA-HR / MSW-HR)",
    ],
    foundationalKnowledge: [
      "Strong understanding of Talent Acquisition and Learning & Development. Proven experience as an L&D Manager or similar role, with a track record of designing and implementing successful training programs.",
    ],
 
    responsePoints: [
      "Source Candidates: Utilize various channels such as social media, job boards, and professional networks to find potential candidates.",
      "Conduct Interviews: Manage the recruitment process, including screening calls, assessments, and interviews.",
      "Maintain ATS: Keep the Applicant Tracking System (ATS) updated with candidate information and recruitment progress.",
      "Employer Branding: Lead initiatives to enhance the company’s employer brand and participate in job fairs and recruitment events.",
      "Onboarding: Facilitate the onboarding process for new hires, ensuring a smooth transition into the company.",
      "Assess Training Needs: Identify training requirements through surveys, interviews, and consultations with managers.",
      "Design Training Programs: Develop and deliver training programs and workshops to improve employee skills and knowledge.",
      "Evaluate Training Effectiveness: Monitor and assess the impact of training programs, making adjustments as needed.",
      "Career Development: Collaborate with department heads to create career development plans for employees.",
      "Monitor Progress: Track and report on training outcomes and employee development.",
      "Stay Updated: Keep abreast of the latest trends and best practices in talent development and incorporate them into training programs."
    ],

    Perks: [
      "Central Location: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  {
    jobid: "6",
    header: "Junior System Administrator",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2025-01-06T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "2 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, Business Administration, or a related field.",
    skil: "Roadmap Development,Backlog Prioritization",
    linkId: "junior-system-administratorat-charles-technologies-4116290477",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative technology solutions. We are seeking a talented and enthusiastic Junior System Administrator to join our growing IT team. In this role, you will play a vital part in supporting the day-to-day operations of our IT infrastructure, ensuring smooth and efficient operations for all employees.",
    requriedexp: [
      "Minimum 2 years of hands-on experience in a system administration or IT support role",
    ],
    foundationalKnowledge: [
      "Strong understanding of IT principles, including networking concepts (TCP/IP, DNS, DHCP), operating systems (Windows, macOS), and cybersecurity best practices.",
    ],
    qualification: [
      "Bachelor's degree in Computer Science, Information Technology, or a related field.",
    ],
    skills: [
      "Strong proficiency in both macOS and Microsoft Windows environments.",
      "Excellent troubleshooting and problem-solving skills.",
      "Experience with Microsoft Office Suite",
    ],

    responsePointsTechnical: [
      "Respond promptly to and resolve user inquiries and technical issues related to hardware, software, and network connectivity",
      "Diagnose and troubleshoot computer problems (e.g., software installations, network issues, hardware malfunctions)",
      "Provide technical support to employees and vendors",
    ],
    responsePointsVendor: [
      "Assist in the procurement of IT equipment and services (e.g., laptops, peripherals, software licenses)",
      "Coordinate with vendors for network services and troubleshooting",
      "Track orders, maintain vendor relationships, and resolve any procurement-related issues",
    ],
    responsePointsSystemtask: [
      "Perform user account management, software installations, and system backups",
      "Monitor system performance and identify potential issues proactively",
      "Assist in the maintenance and troubleshooting of network devices (e.g., routers, switches)",
    ],
    responsePointsSystemIT: [
      "Assist in implementing and maintaining security measures to protect company data and systems",
      "Participate in security audits and vulnerability assessments",
      "Adhere to security protocols and best practices",
    ],
    responsePointsSystemDocumentation: [
      "Document technical procedures and troubleshooting steps",
      "Prepare reports on IT incidents and resolutions",
    ],
    responsePointsSystemAdministration: [
      "Troubleshooting minor issues with printers, scanners and other office equipments",
      "Setting up and troubleshooting A/V equipment for meetings",
      "Assisting with workstation setups during office moves",
      "Maintaining accurate records of IT incidents",
      "Data entry as needed",
    ],
    responsePointsSystemProfessional: [
      "Stay updated on the latest technologies and industry best practices",
      "Actively seek out and participate in training and professional development opportunities to enhance skills",
    ],

    Qualification: [],
    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },
];
  